$light: #ffffff;
$lightgray: #fcfcfc;
$palewhite: #fcfcfc;
$gray: #dddfe2;
$darkgrey: rgba(0, 0, 0, 0.4);
$dark: #000000;
$primary: #0cb9c3;
$primary2: #cdf4f7;
$primary3: #dbeced;
$danger: #ef251a;
$danger2: #c30c0c;
$warning: #ffb904;
$success: #20df27;
$lightblue: #e7f7f9;

$bgGray: #f4f4f4;

$badgeBgDefault: #f8f8f8;
$badgeBgPrimary: $primary3;

$tmp: #afcdfb;
