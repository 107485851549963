@import 'scss/colors';
@import 'scss/destyle';
@import 'scss/font';
@import 'scss/dimensions';
@import 'scss/media-query';
@import 'scss/custom';

:root {
	--primary: #{$primary};
	--primary2: #{$primary2};
	--danger: #{$danger};
	--warning: #{$warning};
	--success: #{$success};
	--light: #{$light};
	--dark: #{$dark};
	--gray: #{$gray};
}

* {
	box-sizing: border-box;
	font-family: Poppins !important;
}

@mixin responsive-sizing {
	font-size: 68%;

	@include small-devices-up {
		font-size: 75%;
	}

	@include medium-devices-up {
		font-size: 80%;
	}

	@include large-devices-up {
		font-size: 90%;
	}

	@include extra-large-devices-up {
		font-size: 100%;
	}
}

html {
	background-color: $bgGray;
	height: 100%;
	overflow-x: hidden;

	@include responsive-sizing;
}

// .IcRemoveScheduleBox {
// 	opacity: 0;
// }

// .dltBtn-ScheduleBox {
// 	// padding-right: 10px;
// 	// padding-left: 10px;
// 	background-color: red;
// }

// .dltBtn-ScheduleBox:hover {
// 	.IcRemoveScheduleBox {
// 		opacity: 1;
// 	}
// 	cursor: pointer;
// }

// body {
// 	height: 100vh;
// 	height: calc(100% - #{$nav-height-xs});

// 	@include small-devices-up {
// 		height: calc(100% - #{$nav-height-sm-up});
// 	}

// 	@include medium-devices-up {
// 		height: calc(100% - #{$nav-height-md-up});
// 	}

// 	@include large-devices-up {
// 		height: calc(100% - #{$nav-height-lg-up});
// 	}

// 	@include extra-large-devices-up {
// 		height: calc(100% - #{$nav-height-xl-up});
// 	}
// }

// #root {
// 	height: 100vh;
// 	overflow-y: hidden;
// }

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}