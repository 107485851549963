.flex {
	display: flex;
}
.justify-center {
	justify-content: center;
}
.justify-between {
	justify-content: space-between;
}
.justify-evenly {
	justify-content: space-evenly;
}
.items-center {
	align-items: center;
}
.w-full {
	width: 100%;
}
.h-full {
	height: 100%;
}

.flex-col {
	flex-direction: column;
}

.flex-1 {
	flex-grow: 1;
}
.flex-2 {
	flex-grow: 1;
}
.flex-3 {
	flex-grow: 1;
}

.mr-auto {
	margin-right: auto;
}
.mt-1 {
	margin-top: 1em;
}
.mb-1 {
	margin-bottom: 1em;
}
.ml-1 {
	margin-left: 1em;
}
.mr-1 {
	margin-right: 1em;
}
.mx-1 {
	margin-left: 1em;
	margin-right: 1em;
}
.my-1 {
	margin-top: 1em;
	margin-bottom: 1em;
}

.mt-2 {
	margin-top: 2em;
}
.mb-2 {
	margin-bottom: 2em;
}
.ml-2 {
	margin-left: 2em;
}
.mr-2 {
	margin-right: 2em;
}
.mx-2 {
	margin-left: 2em;
	margin-right: 2em;
}
.my-2 {
	margin-top: 2em;
	margin-bottom: 2em;
}

.ml-auto {
	margin-left: auto;
}
.mt-auto {
	margin-top: auto;
}
.mb-auto {
	margin-bottom: auto;
}
.mx-auto {
	margin-left: auto;
	margin-right: auto;
}
.my-auto {
	margin-top: auto;
	margin-bottom: auto;
}
// Test
.text-center {
	text-align: center;
}
.text-primary {
	color: #556cd6;
}
.text-white {
	color: #ffffff;
}
.text-bold {
	font-weight: bold;
}
.text-bolder {
	font-weight: bolder;
}

.fixed {
	position: fixed;
}
.top-0 {
	top: 0;
}

.scroll-y {
	overflow-y: scroll;
}
