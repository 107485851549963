@import 'scss/colors';

.typography {
	&-variant {
		&-h1 {
			font-size: 3.875rem; // 62px;
			font-weight: 700;
			line-height: 4.650625rem; // 74.41px;
		}

		&-h2 {
		}

		&-h3 {
			font-size: 1.5rem; // 24px;
			font-weight: 700;
			line-height: 1.8rem; // 28.8px;
		}

		&-h4 {
			font-size: 1.125rem; // 18px;
			font-weight: 500;
			line-height: 1.35rem; // 21.6px;
		}

		&-h5 {
			font-size: 1rem; // 16px;
			font-weight: 400;
			line-height: 1.2rem; // 19.2px;
		}

		&-h6 {
			font-size: 0.75rem; // 12px;
			font-weight: 400;
			line-height: 0.9rem; // 14.4px;
		}

		&-p {
			font-size: 0.875rem; // 14px;
			font-weight: 400;
			line-height: 1.05rem; // 16.8px;
		}

		&-subheading1 {
			font-size: 1.125rem; // 18px;
			font-weight: 400;
			line-height: 1.35rem; // 21.6px;
		}

		&-subheading2 {
			font-weight: 400;
			font-size: 0.75rem; // 12px;
			line-height: 0.9rem; // 14.4px;
		}

		&-body1 {
		}

		&-body2 {
		}
	}

	&-color {
		&-primary {
			color: $primary;
		}

		&-light {
			color: $light;
		}

		&-dark {
			color: $dark;
		}

		&-success {
			color: $success;
		}

		&-warning {
			color: $warning;
		}

		&-danger {
			color: $danger;
		}

		&-gray {
			color: rgba($dark, 0.4);
		}

		&-darkgrey {
			color: rgba($dark, 0.5);
		}
	}

	&-weight {
		&-light {
			font-weight: 300;
		}

		&-regular {
			font-weight: 400;
		}

		&-medium {
			font-weight: 500;
		}

		&-bold {
			font-weight: 700;
		}

		&-heavy {
			font-weight: 900;
		}
	}
}
