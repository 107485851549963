// FONT-WEIGHT MAPPINGS
// --------------------
// 400: regular
// 500: medium
// 700: bold
// 900: heavy

@font-face {
	font-family: 'Gilmer';
	src: local('Gilmer'), url(../res/fonts/Gilmer.woff) format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Gilmer';
	font-weight: 300;
	src: local('Gilmer'), url(../res/fonts/Gilmer-Light.woff) format('woff');
}

@font-face {
	font-family: 'Gilmer';
	font-weight: 500;
	src: local('Gilmer'), url(../res/fonts/Gilmer-Medium.woff) format('woff');
}

@font-face {
	font-family: 'Gilmer';
	font-weight: 700;
	src: local('Gilmer'), url(../res/fonts/Gilmer-Bold.woff) format('woff');
}

@font-face {
	font-family: 'Gilmer';
	font-weight: 900;
	src: local('Gilmer'), url(../res/fonts/Gilmer-Heavy.woff) format('woff');
}

@font-face {
	font-family: 'Gilmer';
	font-weight: 900;
	src: local('Gilmer'), url(../res/fonts/Gilmer-Heavy.woff) format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 700;
	src: local('Montserrat'),
		url(../res/fonts/Montserrat-Bold.woff) format('woff');
}

@font-face {
	font-family: 'Montserrat';
	font-weight: 500;
	src: local('Montserrat'),
		url(../res/fonts/Montserrat-Medium.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	src: local('Poppins'), url(../res/fonts/Poppins-Regular.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 100;
	src: local('Poppins'), url(../res/fonts/Poppins-Thin.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 200;
	src: local('Poppins'),
		url(../res/fonts/Poppins-ExtraLight.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 300;
	src: local('Poppins'), url(../res/fonts/Poppins-Light.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 400;
	src: local('Poppins'), url(../res/fonts/Poppins-Regular.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 500;
	src: local('Poppins'), url(../res/fonts/Poppins-Medium.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 600;
	src: local('Poppins'), url(../res/fonts/Poppins-SemiBold.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 700;
	src: local('Poppins'), url(../res/fonts/Poppins-Bold.woff) format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-weight: 800;
	src: local('Poppins'), url(../res/fonts/Poppins-ExtraBold.woff) format('woff');
}
